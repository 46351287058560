

.read-more {
    background: transparent none repeat scroll 0 0;
    border-radius: 2px;
    color: #bf1e2e;
    display: inline-block;
    height: 40px;
    margin-top: 15px;
    padding: 7px 25px;
    text-align: center;
    letter-spacing: 2px;
    width: 140px;
    border: 2px solid #bf1e2e;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
}

.read-more:after {
    position: absolute;
    content: '➜';
    right: 7px;
    top: 7px;
    font-size: 8px;
    font-weight: 100;
}

.read-more:hover,
.read-more:focus {
    background: #bf1e2e;
    color: #fff;
}


.blog-details h3 a:hover,
.blog-details h3 a:focus {
    color: #bf1e2e;
}

.card-title {
    font-size: 28px;
    margin: 30px auto 50px;
    padding-bottom: 30px;
    letter-spacing: 1px;
    color: #bf1e2e;
    text-align: center;
    font-weight: 600;
}

.card-title::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
}

.card-title::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #bf1e2e;
    bottom: 0;
    left: calc(50% - 20px);
}

.cards-wrapper {
    max-width: 1500px;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .card-title {
        margin-bottom: 20px;
    }
}