.react-datepicker-wrapper {
    display: block;
    margin-top: 10px;
} 

.datePicker-label {
    color: rgba(0,0,0,0.54);
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.00938em;
}

.react-datepicker__input-container input {
    padding: 6px 12px;
    color: rgba(0,0,0,0.54);
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.00938em;
}

.dropzoneStyle {
    width: 100%;
    height: auto;
  border: 2 dashed rgb(102, 102, 102);
    border-radius: 5px;
}

.img-thumbnail {
    height: 100px;
    width: 150px;
    margin-right: 10px;
    object-fit: cover;
}

.react-datepicker-popper {
    z-index: 2;
}

.date-picker-styles {
    margin-top: 20px;
}

.date-picker-styles .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}