.Step1-wrapper .MuiGrid-spacing-xs-2 > .MuiGrid-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Step1-wrapper .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
}

.Step1-wrapper .MuiInputLabel-formControl {
    font-size: 13px !important;
}

input[type="radio"] {
    width: 100px;
    height: 100px;
    display: inline-block;
    background-size: cover;
    -webkit-appearance: none;
}

input[type="radio"]:checked {
    overflow: visible;
}

.categoryIconImage {
    width: 100px;
    height: 100px;
    background: url('../../../assets/images/formImages/box.svg');
    background-size: cover;
}

.Step1-wrapper .MuiGrid-spacing-xs-2 > .checkbox-grid-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.Step1-wrapper .MuiGrid-spacing-xs-2 > .email-grid-wrapper {
    margin-right: auto;
    margin-left: 10px;
    /* margin-top: 30px; */
}


.checkpackage-checkbox label.MuiFormControlLabel-root {
    width: auto;
    height: auto;
}

.addNewParcel-wrapper {
    display: flex;
}

@media (min-width: 600px) {
    .addNewParcel-wrapper .MuiGrid-grid-sm-2 {
        flex-basis: 12.666667%;
    }
}

.p-label {
    margin-bottom: 0;
    font-size: 13px;
    color: rgba(0,0,0,0.54);
}

.p-unit {
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    margin-right: 15px;
    margin-left: 10px;
    margin-bottom: 0;
    color: rgba(0,0,0,0.54);
}


.addNewParcel-wrapper {
    margin-top: 30px;
}

.addNewParcel-wrapper .MuiGrid-root.MuiGrid-container {
    padding: 0 10px;
}

.addNewParcel-wrapper .MuiGrid-spacing-xs-3 > .MuiGrid-item{
    padding: 6px;
}


 .addNewParcel-wrapper .MuiOutlinedInput-input {
    padding: 10.5px 5px;
}

.long-input {
    width: 100%;
}

.addNewParcel-wrapper .MuiButtonBase-root.MuiButton-root {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 10px;
    min-width: 20px;
}

.addNewParcel-wrapper .MuiTypography-h6 {
    font-size: 13px;
}


@media (max-width: 599px) {
    label.MuiFormControlLabel-root {
        width: 45px;
        height: 45px;
    }

    .makeStyles-categoryName-11 {
        text-align: left;
    }

    .Step1-wrapper .MuiGrid-spacing-xs-2 > .MuiGrid-item {
        flex-direction: row;
        justify-content: space-between;
        /* klikalna paczka */
        position: relative;
        margin-top: 10px;
    }

    .Step1-wrapper .MuiGrid-spacing-xs-2 > .MuiGrid-item label.MuiFormControlLabel-root {
        right: 0;
        top: 50%;
        position:absolute;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        background-position: center right;
    }

    .Step1-wrapper .MuiGrid-spacing-xs-2 > .MuiGrid-item label.MuiFormControlLabel-root span.paczka, 
    .Step1-wrapper .MuiGrid-spacing-xs-2 > .MuiGrid-item label.MuiFormControlLabel-root span.paleta, 
    .Step1-wrapper .MuiGrid-spacing-xs-2 > .MuiGrid-item label.MuiFormControlLabel-root span.auto, 
    .Step1-wrapper .MuiGrid-spacing-xs-2 > .MuiGrid-item label.MuiFormControlLabel-root span.przeprowadzka, 
    .Step1-wrapper .MuiGrid-spacing-xs-2 > .MuiGrid-item label.MuiFormControlLabel-root span.ponadgabarytowy {
        background-position: right !important;
    }

}