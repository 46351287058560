
.not-found-btn {
    margin-bottom: 20px;
}

@media (min-width: 600px) {
    .not-found-wrapper {
        min-height: calc(100vh - 328px - 68.25px);
        padding: 50px 0;
    }

    .not-found-btn {
        margin-top: 20px;
    }
}