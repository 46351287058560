.map {
    height: 500px;
    width: 100%;
    overflow: hidden
}

.form-wrapper {
    margin: 50px 0;
}


@media (max-width: 600px) {
    .form-wrapper {
        margin: 20px 0;
    }
}

.contact-info{
    margin-bottom: 25px;
    display: flex;
}

.contact-info__icon{
    margin-right: 20px;
}

.contact-info i, .contact-info span{
    color: #8f9195;
    font-size: 27px;
}

.media-body {
    height: max-content;
}
  
.media-body h3 {
        font-size: 16px;
        margin-bottom: 0;
        font-size: 16px;
        color: #2a2a2a;
}

.media-body p {
        color: #8a8a8a;
}

.email-contact {
    font-size: 14px;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
}

.success  {
    margin: 20px 0;
    border: 1px solid green;
    padding: 7px;
    font-weight: bold;
  }
  
  .error {
    margin: 20px 0;
    border: 1px solid red;
    padding: 7px;
    font-weight: bold;
  }

.form-wrapper a {
    font-size: 14px;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
} 