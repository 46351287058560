.scrollTop {
    position: fixed; 
    width: 10%;
    bottom: 30px;
    align-items: center;
    height: 20px;
    justify-content: left;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    color: #bf1e2e;
    right: 30px;
  }
  
  .scrollTop:hover{
    opacity: 1;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }