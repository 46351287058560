* {box-sizing: border-box;}

.contact-form {
  max-width: 510px;
  margin: 30px auto;
}

.contact-form__container {
  border: 1px solid #f5f5f5;
  padding: 35px;
  box-shadow: 2px 2px 10px 0 #f5f5f5;
}
.contact-form__container p{
  margin: 0;
  text-align: left;
}
.contact-form__header h2{
  color: #4a4a4a;
}
.contact-form__header p{
  margin-bottom: 0;
  color: #4a4a4a;
  color: #311c56;
}
.contact-form__header{
  background: #f7f7f7;
  padding: 2px 35px 31px;
  margin: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
p {
  font-weight: 500;
}


.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}
.success  {
  margin: 20px 0;
  border: 1px solid green;
  padding: 7px;
  font-weight: bold;
  width: fit-content;
  color: green;
}

.error {
  margin: 20px 0;
  border: 1px solid red;
  padding: 7px;
  font-weight: bold;
  color: red;
  width: fit-content;
}

.form {
  width: 100%;
}

.contact-info{
  display: flex
}

.contact-info__icon{
  margin-right: 20px;
}

.contact-info i, .contact-info span{
  color: #8f9195;
  font-size: 27px;
}