.my-footer {
  background-color: #24292e;
  font-family: 'Roboto', sans-serif;
  padding: 30px 0 0 0;
  
}

.footer-section-wrapper {
  border-bottom: 1px solid #aaa;
}

.footer-mb {
  border-bottom: 1px solid #aaa;
  margin-bottom: 30px;
}

.footer-sidebar-wrap {
    display: flex;
    flex-direction: column;
}

.footer-sidebar-wrap h3 {
  text-transform: uppercase;
  color: white;
  margin-top: 30px;
  text-align: left;
  font-size: 14px;
}

.footer-sidebar-wrap li {
  list-style: none !important;
  text-align: left;
  color: #aaa;
}

.footer-sidebar-wrap p {
  color: #aaa;
  margin: 5px 0px;
}

.footer-logo {
  margin-top: 30px;
}

.footer-contact-part p {
  text-align: left;
}

.footer-sidebar-wrap i {
  margin-right: 10px;
  font-size: 20px;
}

.footer-help-part p {
  text-align: left;
  margin-bottom: 10px;
}

.footer-help-part a:hover {
  color: #fff;
}

.footer-help-part a {
  color: #aaa;
}

.footer-sidebar-wrap a {
  margin: 5px 0px;
  text-decoration: none;
  color: #aaa;
}

.footer-sidebar-wrap a:hover {
  color: #fff;
}
/* footer */

.social-buttons a{
  display: inline-block;
  font-size: 30px;
  color: white;
  margin-right: 10px;
}

/* .social-buttons a:hover {
  color: 
} */

.manufacturer {
  width: 100%;
  text-align: right;
  line-height: 21px;
  font-size: 11px;
  background-color: #24292e;
  padding: 10px 0px 0px 0px;
  letter-spacing: 1px;
  color: #aaa;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid white;
}

.footer-copyright {
  width: 50%;
  color: #aaa;
  background-color: #24292e;
  padding-bottom: 10px;
  text-align: left;
}

.manufacturer a {
  display: inline-block;
  margin: 0;
  padding: 0;
  opacity: 0.5;
}

.manufacturer a:hover {
  opacity: 1;
}

.manufacturer img {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  margin-top: -5px;
  width:70px;
}

@media (max-width: 600px) {
  .footer-copyright {
    width: 100%;
    text-align: center;
  }

  .manufacturer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .shovv-logo {
    margin-bottom: 10px;
  }
}
