.section-wrapper {
  padding: 50px 0;
}

@media (max-width: 600px) {
  .section-wrapper {
      padding: 20px 0;
  }
}

.list {
    list-style: none;
    padding-left: 0;
  }
  
.list li {
    position: relative;
    padding-left: 50px;
    margin-bottom: 15px;
    padding-top: 15px;
  }
  
.list li::before {
    font-family: 'FontAwesome'; 
    content: '\f00c';
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 10px;
    font-size: 30px;
    text-align: center;
    color: #c2352d;
  }


  @media (max-width: 600px) {
    h2.card-title {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
