



.section-wrapper {
    padding: 50px 0;
}

@media (max-width: 600px) {
    .section-wrapper {
        padding: 20px 0;
    }
}

 .map-section {
     padding: 50px 0;
    min-height: 50vh;
    position: relative;
    background: black;
 } 

 @media (max-width: 600px) {
    .map-section {
        padding: 20px 0;
    }
 }

.map-section:after {
    content: '';
    background: url('../../assets/images/planeta.jpg') no-repeat center center/cover;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* z-index: 0; */
    opacity: .3;
    position: absolute;
}

.mapa-content h3{
    text-align: center;
    color: #fff;
}

.mapa-content p {
    text-align: center;
    color: #fff;
    margin-top: 30px;
}

.mapa {
    position: relative;
    
}

@media (max-width: 768px) {
    .mapa {
        padding-left: 0;
    };
}


/* .mapa::after {
    background: #3c4147;
    content: '';
    height: 100%;
    opacity: 0.9;
    position: absolute;
       top: 0;
       width: 100%;
     } */

.contact-offer-btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
}

@media (max-width: 991px) {
    .oferta-title {
        margin-top: 100px;
    }
}