/* PRICES SECTION */
.prices {
    padding: 50px 0;
    background: #24292e;
  }

  .prices table {
      border: 2px solid black;
      text-align: center;
      width: 100%;
      height: 100%;
  }

  table tbody {
      border: 1px solid black;
  }

  th {
      border: 1px solid black;
      padding: 8px 16px;
      text-transform: uppercase;
      font-size: 20px;
      color: white;
      letter-spacing: 1px;
      font-weight: 600;
  }

  tr {
      border: 1px solid black;
  }

  td {
      border: 1px solid black;
      padding: 8px 16px;
      color: white;
      font-size: 18px;
  }

  .promo {
      background: #bf1e2e;
      text-transform: uppercase;
      color: white;
      
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 600;
  }

  .prices h3 {
      text-align: center;
      color: white;
      margin-top: 20px;
      font-size: 28px;
      margin: 0 auto;
      letter-spacing: 1px;
      font-weight: 600;
  }

  .prices p {
      color: white;
  }


  @media (max-width: 600px) {
      th, td, .promo {
          font-size: 14px
      }
  }